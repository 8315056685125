import React, { useState, useEffect } from 'react';
import LeftSideMenu from '../components/LeftSideMenu';
import UserNav from '../components/UserNav';
import { Link } from 'react-router-dom';
import { Button, Image, Modal, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import Loader from '../components/Loader';

const Projects = () => {

    const [loading, setLoading] = useState(false)

    const [createModalshow, setCreateModalShow] = useState(false);
    const [projectName, setProjectName] = useState('')
    const navigate = useNavigate()

    const handleCloseModal = () => setCreateModalShow(false);
    const handleShowModal = () => setCreateModalShow(true);

    const handleNext = () => {
        if(projectName.trim()){
            navigate('/create-project', {state: {projectName}})
        }else{
            toast.error('Projectname field cannot be empty!')
        }
    }

    return (
        <>
            {loading ? <Loader /> : 
            <div className='pagecontent'>
                <UserNav />
                <LeftSideMenu />
                <div className='pagecontentbox'>
                    <div className='pagebannerflex'>
                        <div className="pagetitle col-lg-6">
                            <h2 className="pagetitleh2">Personalize Your Developer Portfolio</h2>
                            <p className='content userpagecontent'>Welcome to your profile editor! Customize your portfolio by updating your information below. Your details will be displayed on your portfolio page, giving visitors a snapshot of your skills, experience, and personality. Make sure to save changes to keep your portfolio current and engaging!</p>
                        </div>
                        <div className='pagebannerimgbg'>
                            <Image src={`${process.env.PUBLIC_URL}/images/editbanner.webp`} className='bannerimg' alt='bannerimg' />
                        </div>
                    </div>
                </div>
                <div className='pagecontentbox'>
                    <div className='createbanner'>
                        <div className="pagetitle col-lg-6">
                            <h2 className="pagetitleh2">Start Building Your Dream Website!</h2>
                            <p className='content userpagecontent'>Turn your ideas into reality with LandingFast!. No coding required.</p>
                        </div>
                        <div className='pagebannerimgbg'>
                            <Button onClick={handleShowModal} className='btn sitebtn userbtn'>+ Create A New Website</Button><br />
                            <small>Get Started In Less Than A Minute!</small>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal centered show={createModalshow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Project Name</Form.Label>
                            
                            <Form.Control
                                type="text"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="devfolio"
                                autoFocus
                            />
                            <small>Project name is used as the identifier of the website.</small>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleNext}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default Projects