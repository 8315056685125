import React from 'react'
import LeftSideMenu from '../components/LeftSideMenu'
import UserNav from '../components/UserNav'

const Blog = () => {
    return (
        <>
            <div className='pagecontent'>
                <UserNav />
                <LeftSideMenu />
                <div className='pagecontentbox'>
                    <div className="pagetitlediv">
                        <h2 className="title">Blog</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog