

import React, { useState } from 'react'
import LeftSideMenu from '../components/LeftSideMenu'
import UserNav from '../components/UserNav'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Row, Col, Form, Tab, Nav, Image } from 'react-bootstrap'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import axios from 'axios';

import { useAddProjectMutation } from '../slices/usersApiSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader'

const animatedComponents = makeAnimated();

const options = [
    // Frontend Development
    { value: 'HTML', label: 'HTML' },
    { value: 'CSS', label: 'CSS' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'React', label: 'React' },
    { value: 'Vue', label: 'Vue' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Svelte', label: 'Svelte' },
    { value: 'Next.js', label: 'Next.js' },
    { value: 'Gatsby', label: 'Gatsby' },
    { value: 'Bootstrap', label: 'Bootstrap' },
    { value: 'Tailwind CSS', label: 'Tailwind CSS' },
    { value: 'Material-UI', label: 'Material-UI' },
    { value: 'jQuery', label: 'jQuery' },

    // Backend Development
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Express', label: 'Express' },
    { value: 'Django', label: 'Django' },
    { value: 'Flask', label: 'Flask' },
    { value: 'Ruby on Rails', label: 'Ruby on Rails' },
    { value: 'Laravel', label: 'Laravel' },
    { value: '.NET', label: '.NET' },
    { value: 'Spring Boot', label: 'Spring Boot' },

    // Programming Languages
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'C++', label: 'C++' },
    { value: 'C#', label: 'C#' },
    { value: 'PHP', label: 'PHP' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'Go', label: 'Go' },
    { value: 'Rust', label: 'Rust' },

    // Databases
    { value: 'MySQL', label: 'MySQL' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'SQLite', label: 'SQLite' },
    { value: 'Redis', label: 'Redis' },
    { value: 'Firebase', label: 'Firebase' },
    { value: 'Oracle Database', label: 'Oracle Database' },

    // Cloud & DevOps
    { value: 'AWS', label: 'AWS' },
    { value: 'Azure', label: 'Azure' },
    { value: 'Google Cloud', label: 'Google Cloud' },
    { value: 'Docker', label: 'Docker' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'Jenkins', label: 'Jenkins' },
    { value: 'Git', label: 'Git' },
    { value: 'GitHub', label: 'GitHub' },
    { value: 'GitLab', label: 'GitLab' },
    { value: 'CircleCI', label: 'CircleCI' },

    // Design & Prototyping
    { value: 'Adobe Photoshop', label: 'Adobe Photoshop' },
    { value: 'Adobe Illustrator', label: 'Adobe Illustrator' },
    { value: 'Figma', label: 'Figma' },
    { value: 'Sketch', label: 'Sketch' },
    { value: 'Adobe XD', label: 'Adobe XD' },
    { value: 'InVision', label: 'InVision' },
    { value: 'Canva', label: 'Canva' },

    // Digital Marketing Tools
    { value: 'Google Analytics', label: 'Google Analytics' },
    { value: 'Google Ads', label: 'Google Ads' },
    { value: 'Facebook Ads', label: 'Facebook Ads' },
    { value: 'HubSpot', label: 'HubSpot' },
    { value: 'Mailchimp', label: 'Mailchimp' },
    { value: 'Hootsuite', label: 'Hootsuite' },
    { value: 'SEMrush', label: 'SEMrush' },
    { value: 'Ahrefs', label: 'Ahrefs' },
    { value: 'Salesforce', label: 'Salesforce' },
    { value: 'Buffer', label: 'Buffer' },

    // Content Management Systems
    { value: 'WordPress', label: 'WordPress' },
    { value: 'Drupal', label: 'Drupal' },
    { value: 'Joomla', label: 'Joomla' },
    { value: 'Contentful', label: 'Contentful' },
    { value: 'Strapi', label: 'Strapi' },
    { value: 'Shopify', label: 'Shopify' },
    { value: 'Magento', label: 'Magento' },

    // Project Management
    { value: 'Trello', label: 'Trello' },
    { value: 'Jira', label: 'Jira' },
    { value: 'Asana', label: 'Asana' },
    { value: 'Notion', label: 'Notion' },
    { value: 'Monday.com', label: 'Monday.com' },
    { value: 'Slack', label: 'Slack' },
    { value: 'Microsoft Teams', label: 'Microsoft Teams' },
];


const EditPortfolio = () => {

    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const { projectName } = location.state || {}
    const { templateName } = location.state || {}

    const [addProject] = useAddProjectMutation(); // Use the mutation from your Redux Toolkit
    const [formData, setFormData] = useState({
        projectName: projectName,
        template: templateName,
        proImgUrl: '',
        tagline: '',
        heroHeading: '',
        heroSubHeading: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'ml_default'); // Replace with your Cloudinary upload preset
        formData.append('folder', 'project_images'); // Optional folder

        try {
            const response = await axios.post('https://api.cloudinary.com/v1_1/do2pizjsh/image/upload', formData);
            return response.data.secure_url; // Return the uploaded image's URL
        } catch (error) {
            toast.error('Error uploading image to Cloudinary!');
            console.log(error)
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Upload the image if a file is selected
            let uploadedImageUrl = formData.proImgUrl;

            if (formData.proImgUrl instanceof File) {
                uploadedImageUrl = await handleImageUpload(formData.proImgUrl);
                setFormData((prev) => ({ ...prev, proImgUrl: uploadedImageUrl }));
            }

            // Prepare the payload
            const payload = {
                projectName: formData.projectName,
                template: formData.template,
                landingPageData: {
                    proImgUrl: uploadedImageUrl,
                    tagline: formData.tagline,
                    heroHeading: formData.heroHeading,
                    heroSubHeading: formData.heroSubHeading,
                },
            };

            // Pass userId and accessToken to the mutation
            const response = await addProject({
                userId,
                userData: payload,
                token: accessToken,
            }).unwrap();

            toast.success('Project Created successfully!');
            const newTab = window.open(`/${formData.projectName}`, '_blank');
            if (newTab) {
                newTab.focus();
            }
            navigate('/dashboard');
        } catch (error) {
            console.error('Error adding project:', error);
            toast.error('Failed to create project.');
        }
        finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (e.target.type === 'file') {
            setFormData((prev) => ({ ...prev, [name]: e.target.files[0] })); // Handle file input
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };


    return (
        <>
            {loading
                ?
                <Loader />
                :
                <div className='pagecontent'>
                    <UserNav />
                    <LeftSideMenu />
                    <div className='pagecontentbox'>
                        <div className='pagebannerflex'>
                            <div className="pagetitle col-lg-6">
                                <h2 className="pagetitleh2">Personalize Your {projectName} Website using {templateName} Theme! <span className='titlespan'><h6 className='h6'><Link href='#'>Preview</Link></h6></span></h2>
                                <p className='content userpagecontent'>Welcome to your profile editor! Customize your portfolio by updating your information below. Your details will be displayed on your portfolio page, giving visitors a snapshot of your skills, experience, and personality. Make sure to save changes to keep your portfolio current and engaging!</p>
                            </div>
                            <div className='pagebannerimgbg'>
                                <Image src={`${process.env.PUBLIC_URL}/images/editbanner.webp`} className='bannerimg' alt='bannerimg' />
                            </div>
                        </div>
                    </div>
                    <div className='pagecontentbox'>
                        <div className='editflexbox'>
                            <Tab.Container defaultActiveKey="hero">
                                <div className='landingmenusection'>
                                    <div>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="hero">Hero Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tech">Tech Stacks / Tools Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="experience">Professional Experience Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="pow">Proof Of Work Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="contact">Contact Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="submit">Submit</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>

                                <SimpleBar style={{ maxHeight: 600 }}>
                                    <div className='landingformsection'>
                                        <Form onSubmit={handleSubmit}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="hero">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='profileimgbox'>
                                                                <div className='my-2'>
                                                                    <span className="proimgbg"><img src="/images/v.webp" alt="profileimage" className="proimg" /></span>
                                                                </div>
                                                                <h6 className='h6'>Tailor Your Profile Display</h6>
                                                                <p className='userpagecontent'>Update your profile photo to personalize your portfolio and make a lasting impression!</p>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId="formFile" className="mb-3">
                                                                            <Form.Label>Upload Your Profile Pricture</Form.Label>
                                                                            <Form.Control type="file"
                                                                                name="proImgUrl"
                                                                                accept="image/*"
                                                                                onChange={handleInputChange} />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="formGridAddress1">
                                                                            <Form.Label>Profile Tagline</Form.Label>
                                                                            <Form.Control
                                                                                name="tagline"
                                                                                value={formData.tagline}
                                                                                onChange={handleChange}
                                                                                placeholder="Devmaster"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Button className='btn sitebtn borderbtn userbtn me-3'>Upload New</Button> */}
                                                            </div>
                                                        </div>
                                                        <div className='landingsections'>
                                                            <div className='biobg'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Tailor Your Profile Display</h6>
                                                                </div>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Label>Edit Your Heading
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name="heroHeading"
                                                                        value={formData.heroHeading}
                                                                        onChange={handleChange}
                                                                        as="textarea"
                                                                        rows={3}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                    <Form.Label>Edit Your Bio
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name="heroSubHeading"
                                                                        value={formData.heroSubHeading}
                                                                        onChange={handleChange}
                                                                        type='text'
                                                                        as="textarea"
                                                                        rows={3}
                                                                    />
                                                                </Form.Group>
                                                                <div className='d-flex gap-2'>
                                                                    <Button className='btn sitebtn userbtn aibtn'>Use AI</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='landingsections'>
                                                            <div className='socmedialinks'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Social Media Links</h6>
                                                                </div>
                                                                <div>
                                                                    <SimpleBar style={{ maxHeight: 260 }}>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Label>Facebook
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name="links.github"
                                                                                type="text"
                                                                                placeholder="facebook.com/john"
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>X
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name="links.linkedin"
                                                                                type="text"
                                                                                placeholder="facebook.com/john"
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>Instagram
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                name="links.email"
                                                                                type="text"
                                                                                placeholder="facebook.com/john"
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>Linkedin
                                                                            </Form.Label>
                                                                            <Form.Control type="text" placeholder="linkedin.com/john" />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>Github
                                                                            </Form.Label>
                                                                            <Form.Control type="text" placeholder="github.com/john" />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>Dribble
                                                                            </Form.Label>
                                                                            <Form.Control type="text" placeholder="dribble.com/john" />
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                            <Form.Label>Behance
                                                                            </Form.Label>
                                                                            <Form.Control type="text" placeholder="behance.com/john" />
                                                                        </Form.Group>
                                                                    </SimpleBar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tech">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='stacksbox'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>
                                                                        Skills & Tools</h6>
                                                                    <p className='userpagecontent'>Select the skills and tools that showcase your expertise across development, design, and marketing. Highlight what powers your projects!</p>
                                                                </div>
                                                                <Select
                                                                    closeMenuOnSelect={false}
                                                                    components={animatedComponents}
                                                                    isMulti
                                                                    options={options}
                                                                    value={options}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="experience">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='experiencebox'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Your Career Highlights</h6>
                                                                    <p className='userpagecontent'>Showcase your professional journey and highlight the roles that define your expertise!</p>
                                                                </div>
                                                                <SimpleBar style={{ maxHeight: 260 }}>
                                                                    <div className='formflex'>
                                                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Label>Enter Company Name</Form.Label>
                                                                            <Form.Control
                                                                                name="company"
                                                                                type="text"
                                                                                placeholder="Zoho"
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label>Select Year</Form.Label>
                                                                            <Form.Select aria-label="Default select example">
                                                                                <option>Select Year</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label>Select Month</Form.Label>
                                                                            <Form.Select aria-label="Default select example">
                                                                                <option>Select Month</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='mt-3'>
                                                                        <Button className='btn sitebtn borderbtn userbtn me-3'>Add More</Button>
                                                                    </div>
                                                                </SimpleBar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="pow">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='projectsection'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Showcase Your Projects</h6>
                                                                </div>
                                                                <div>
                                                                    <div className='contentboxtitle'>
                                                                        <p className='content sectionsubtitle'>Project 1</p>
                                                                    </div>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Project Name
                                                                        </Form.Label>
                                                                        <Form.Control type="text" placeholder="project" />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Project Link
                                                                        </Form.Label>
                                                                        <Form.Control type="text" placeholder="project.com" />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <Button className='btn sitebtn borderbtn userbtn me-3'>Add More</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="contact">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='biobg'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Contact Section</h6>
                                                                </div>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Label>Edit Your Heading
                                                                    </Form.Label>
                                                                    <Form.Control className='headingform' as="textarea" rows={3} />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                    <Form.Label>Edit Your Sub-Heading
                                                                    </Form.Label>
                                                                    <Form.Control className='headingform' as="textarea" rows={3} />
                                                                </Form.Group>
                                                                <div className='d-flex gap-2'>
                                                                    <Button className='btn sitebtn userbtn aibtn'>Use AI</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="submit">
                                                    <div className='landingsectionflex'>
                                                        <div className='landingsections'>
                                                            <div className='biobg'>
                                                                <div className='contentboxtitle'>
                                                                    <h6 className='h6'>Submit Your Details</h6>
                                                                    <p className='userpagecontent'>Stand Out with a Stunning Online Profile!</p>
                                                                </div>
                                                                <div className='d-flex gap-2'>
                                                                    <Button type='submit' className='btn sitebtn userbtn'>Submit
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Form>
                                    </div>
                                </SimpleBar>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer />
        </>
    )
}

export default EditPortfolio