import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { useGetProjectByProjectnameQuery } from '../slices/usersApiSlice';
import HomeHeader from '../components/HomeHeader';
import Hero from '../components/Hero';
import Stacks from '../components/Stacks';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';

const DeveloperPortfolio = () => {
  const { projectName } = useParams(); // Getting projectName from URL params
  const navigate = useNavigate();

  // Fetching the project data based on projectName
  const { data, error, isLoading } = useGetProjectByProjectnameQuery(projectName);

  if (isLoading) {
    return <Loader />; // Show a loading state while data is being fetched
  }

  if (error) {
    return <div>Error loading project data</div>; // Handle error
  }

  // Destructure the data from the response
  const { template, landingPageData, projectName: fetchedProjectName } = data?.project || {};
  const { proImgUrl, tagline, heroHeading, heroSubHeading } = landingPageData || {};

  return (
    <>
      <HomeHeader data={data?.project} /> {/* Pass the fetched data to components */}
      <section className="homebannerbg">
                <Container className='sitebannercontent'>
                    <Row>
                        <Col lg={8} className="homecol text-center mx-auto">
                            <div className="bannercontent">
                                <div className='mt-5'>
                                    <span className='proimgbg'><Image src={proImgUrl} alt='profileimage' className='proimg' /></span>
                                </div>
                                <Badge className='custombadge'>{tagline} <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                                <h2 className="maintitl">{heroHeading}</h2>
                                <div className='col-lg-6 mx-auto'>
                                    <p className="content">
                                    {heroSubHeading}
                                    </p>
                                </div>
                                <div className='btnflex mb-3'>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/github.svg' alt='stackicon' className='toolicon'/></span>Github</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/linkedin.svg' alt='stackicon' className='toolicon'/></span>Linkedin</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/mail.svg' alt='stackicon' className='toolicon'/></span>Email</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/experience.svg' alt='stackicon' className='toolicon'/></span>Resume</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
      <Stacks template={template} />
      <Experience />
      <Projects />
      <Contact />
    </>
  );
}

export default DeveloperPortfolio;
