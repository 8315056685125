import React, { useEffect, useState } from 'react';
import LeftSideMenu from '../components/LeftSideMenu';
import UserNav from '../components/UserNav';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useUpdateUserMutation } from '../slices/usersApiSlice';
import { useSelector } from 'react-redux';
import { config } from '../config/indexConfig';
import Loader from '../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';


const Dashboard = () => {
    const accessToken = localStorage.getItem('accessToken');
    const userID = useSelector((state) => state.auth.userId);

    const [username, setUsername] = useState('');
    const [hasUsername, setHasUsername] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    const [updateUser, { isLoading }] = useUpdateUserMutation({
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    // Fetch user data to check if username exists
    useEffect(() => {
        const fetchUserInfo = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${config.api.baseUrl}/users/${userID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();

                if (response.ok) {
                    setHasUsername(!!data.username); // Set hasUsername based on the presence of username
                } else {
                    console.error('Failed to fetch user data:', data.message);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false); // Set loading to false once fetch completes
            }
        };

        fetchUserInfo();
    }, [userID, accessToken]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await updateUser({
                userId: userID,
                userData: { username }
            }).unwrap();

            toast.success('Profile updated successfully');
            setHasUsername(true); // Set username as present after successful update
        } catch (err) {
            const errorMsg = err?.data?.message || 'Something went wrong';
            console.log(errorMsg);
            toast.error(errorMsg);
        }
    };

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className='pagecontent'>
                <UserNav />
                <LeftSideMenu />
                <div className='pagecontentbox'>
                    <div className='pagebannerflex'>
                        <div className="pagetitle col-lg-6">
                            <h2 className="pagetitleh2">Elevate Your Website Look With LandingFast Themes!</h2>
                            <p className='content userpagecontent'>Discover the perfect theme for your LandingFast Store. </p>
                        </div>
                        <div className='pagebannerimgbg'>
                            <Image src='images/banner.png' className='bannerimg' alt='bannerimg' />
                        </div>
                    </div>
                </div>
                <div className='pagecontentbox'>

                    {hasUsername ? (
                        <div className='text-center'>
                            <h6 className='h6'>🎉 Let's get started! Select your <span><Link to='/projects'>LandingFast</Link></span> theme!</h6>
                            <Image src='images/editbanner.webp' className='welcomdashimg' alt='dashimg'/>
                        </div>
                    ) : (

                        <div className='getdetailsbox'>
                            <div className='boxtitle pagetitle'>
                                <h2 className='pagetitleh2'>Your Job, Your Site: What Do You Do, and What Website Suits You?</h2>
                                <h6 className='h6'>Tell us a little more about yourself! Use high quality images and product descriptions to have a great looking product page. Let's get started.</h6>
                                <div className='usernameaddcard'>
                                    <p className='content userpagecontent'>Please add your username</p>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter your username'
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                            <Form.Label>Describe Your Work: Let's Build the Right Website for You</Form.Label>
                                        <div className='fomradioflex'>
                                            <Form.Check
                                                type="radio"
                                                name="imageRadioOptions"
                                                id="option1"
                                                label={
                                                    <Image
                                                        src='images/dashimg.svg'
                                                        alt="Option 1"
                                                        rounded
                                                        width="100px"
                                                        height="100px"
                                                    />
                                                }
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="imageRadioOptions"
                                                id="option2"
                                                label={
                                                    <Image
                                                        src='images/rocket.svg'
                                                        alt="Option 2"
                                                        rounded
                                                        width="100px"
                                                        height="100px"
                                                    />
                                                }
                                            />
                                        </div>

                                        <Button
                                            className="btn userbtn sitebtn"
                                            type='submit'
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Updating...' : 'Update'}
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        // <div className='usernameaddcard'>
                        //     <h6>Please add your username to continue</h6>
                        //     <Form onSubmit={handleSubmit}>
                        //         <Form.Group className='mb-3'>
                        //             <Form.Label>Username</Form.Label>
                        //             <Form.Control
                        //                 type='text'
                        //                 placeholder='Enter your username'
                        //                 value={username}
                        //                 onChange={(e) => setUsername(e.target.value)}
                        //                 required
                        //             />
                        //         </Form.Group>
                        //         <Button
                        //             className="btn sitebtn"
                        //             type='submit'
                        //             disabled={isLoading}
                        //         >
                        //             {isLoading ? 'Updating...' : 'Add Username'}
                        //         </Button>
                        //     </Form>
                        // </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default Dashboard;
